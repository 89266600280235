@import url('https://fonts.googleapis.com/css2?family=Raleway&display=swap');

.App {
  text-align: center;
}

body {
  font-size: larger;
  font-family: 'Raleway', sans-serif;
}

.head2 {
  margin-top: 8px;
}

.stat {
  text-align: center;
  width: 20vw;
  margin: 8px 16px;
  border-radius: 8px;
  padding: 0 8px 4px;
  transition: box-shadow 200ms ease-in-out;
}

.stat:hover {
  box-shadow: 0 0 8px 4px #60c1eb;
}

.sm {
  margin-top: -8px;
  font-size: medium;
}

.small {
  font-style: italic;
}

.inline-link {
  color: #60c1eb;
}

.link {
  font-weight: 600;
  text-decoration: none;
  color: black;
}

.link::before {
  margin-right: 8px;
  font-weight: bolder;
  color: transparent;
  content: "\227B";
  transition: all 0.2s ease-in;
}

.link::after {
  margin-left: 8px;
  color: transparent;
  content: "\227A";
  transition: all 0.2s ease-in;
}

.link:hover::before {
  color: #60c1eb;
}

.link:hover::after {
  color: #60c1eb;
}

.link:hover {
  color: #60c1eb;
}

.welcome {
  width: clamp(320px, 25vw, 480px);
  height: clamp(320px, 25vw, 480px);
  perspective: 1000px;
  background-color: transparent;
  border-radius: 50%;
  animation: 1.5s linear appear, pulse 0.9s linear 0.8s;
}

.welcome-inner {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: inherit;
  border-radius: inherit;
  transform-style: preserve-3d;
  transition: transform 0.6s ease-in-out;
}

.welcome-front {
  border-radius: inherit;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: clamp(48px, 4vw, 64px);
  width: 100%;
  height: 100%;
}

.welcome:hover .welcome-inner {
  transform: rotateY(180deg);
}

.welcome-front, .welcome-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.welcome-back {
  transform: rotateY(180deg);
  background-image: url(./portrait.png);
  background-size: cover;
  border-radius: inherit;
}

.bar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
}

.options {
  min-width: 20vw;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.video {
  width: 560px;
  height: 315px;
  border-radius: 8px;
  box-shadow: 8px 8px 8px rgba(156, 156, 156, 0.5);;
}

.cards {
  width: 80vw;
  padding-top: 16px;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: stretch;
}

#stats {
  flex-wrap: wrap;
  padding-bottom: 48px;
}

.learn {
  padding: 16px;
  font: inherit;
  font-size: large;
  border-radius: 8px;
  border: solid;
  background-color: white;
  border-color: #60c1eb;
  color: #60c1eb;
  transition: box-shadow 500ms ease-in-out, color 500ms ease-in-out;
}

.learn:active {
  transform: translateY(2px);
}

.learn:hover {
  color: white;
  box-shadow: 0 0 40px 40px #60c1eb inset;
}

.card {
  text-align: center;
  padding: 0 16px;
  margin: 32px;
  border-style: solid;
  border-color: #60c1eb;
  border-radius: 8px;
  min-height: 25vh;
  width: 20vw;
  box-shadow: 8px 8px 8px rgba(156, 156, 156, 0.5);
  transition: all 0.2s ease-in-out;
}

.card:hover {
  transform: scale(1.1);
}

.stick {
  animation: 1.5s ease-in 0s 1 slide-top, 1.5s linear 0s 1 fade-in;
  position: fixed;
  top: 0px;
  background-color: #ffffffcc;
  backdrop-filter: blur(12px);
  z-index: 10;
  width: 100%;
}

.home {
  min-height: 100vh;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.content {
  padding: 48px 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.text {
  padding: 48px 16px;
  width: 80vw;
  text-align: left;
}

.social {
  transition: transform 0.2s ease-in-out;
}

.social:hover {
  transform: scale(1.2);
}

#logo {
  height: clamp(48px, 4vw, 64px);
  width: clamp(48px, 4vw, 64px);
  position: absolute;
  bottom: 48px;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

#hey {
  font-size: clamp(24px, 2vw, 32px);
  position: absolute;
  top: clamp(64px, 4vw, 85px);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

#home-logo {
  width: 54px;
  height: 54px;
}

#home-logo:hover #home-logo-path {
  stroke: #60c1eb;
}

.popup {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 11;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  background-color: #dcdcdc80;
  backdrop-filter: blur(4px);
}

.close {
  font-size: 48px;
  margin: -32px -10px 0 0;
  align-self: flex-end;
  top: 8px;
  cursor: pointer;
}

.close:hover {
  color: #60c1eb;
}

.popup-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: white;
  width: 70vw;
  max-height: 80vh;
  padding: 20px 20px 40px;
  border-radius: 8px;
  border: 2px solid #60c1eb90;
  animation: expand 100ms linear;
}

.details {
  width: 100%;
  height: 100%;
  padding: 16px;
  overflow-y: auto;
}

.pop-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#pop {
  padding: 0px 16px 16px;
  width: 95%;
}

#top {
  background: linear-gradient(to bottom, white 80%, transparent 100%);
}

#bottom {
  background: linear-gradient(to top, white 80%, transparent 100%);;
}

#mobile-video {
  display: none;
}

.radial {
  height: clamp(512px, 40vw, 768px);
  width: clamp(512px, 40vw, 768px);
  position: absolute;
  z-index: -1;
}

.main-circle {
  r: 240px;
  cx: 50%;
  cy: 50%;
  fill: transparent;
  stroke-linecap: round;
  stroke-width: 16px;
  stroke-dasharray: 251;
  stroke-dashoffset: 1506;
  animation: rotate 20s linear 1.7s infinite, coloring1 1.8s linear;
  transition: stroke 0.6s ease-in;
}

#second {
  r: 264px;
  stroke-width: 8px;
  stroke-dasharray: 300 700;
  stroke-dashoffset: 2000;
  animation: rotate 12s linear 1.7s infinite, coloring2 1.8s linear;
}

#third {
  r: 280px;
  stroke-width: 4px;
  stroke-dasharray: 300 650 150 100;
  stroke-dashoffset: 2400;
  animation: rotate 24s linear 1.7s infinite, coloring3 1.8s linear;
}

@keyframes rotate {
  to {
    stroke-dashoffset: 0;
  }
}

@keyframes slide-top {
  from {
    transform: translateY(-100%);
  } to {
    transform: translateY(0);
  }
}

@keyframes fade-in {
  from {
    opacity: 0;
  } to {
    opacity: 1;
  }
}

@keyframes appear {
  from {
    opacity: 0;
    transform: scale(0.1);
  } to {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes expand {
  from {
    transform: scale(0.5);
  } to {
    transform: scale(1);
  }
}

@keyframes coloring1 {
  from {
    stroke: #00000090;
  } to {
    stroke: #60c1eb90;
  }
}

@keyframes coloring2 {
  from {
    stroke: #00000060;
  } to {
    stroke: #60c1eb60;
  }
}

@keyframes coloring3 {
  from {
    stroke: #00000040;
  } to {
    stroke: #60c1eb40;
  }
}

@keyframes pulse {
  0% {
    box-shadow: none;
  }
  70% {
    box-shadow: 0 0 48px #60c1eb, 0 0 48px 8px #60c1eb inset;
  }
  100% {
    box-shadow: none;
  }
}

@media only screen and (max-width: 800px) and (min-width: 480px) {
  .cards {
    flex-direction: column;
    align-items: center;
  }

  .card {
    width: 60vw;
  }

  .stat {
    width: 70vw;
  }
}

@media only screen and (max-width: 480px) {
  .cards {
    flex-direction: column;
    align-items: center;
  }

  .card {
    width: 60vw;
  }

  .stat {
    width: 70vw;
  }

  .App {
    font-size: medium;
  }

  .text {
    width: 90vw;
  }

  .welcome {
    height: 240px;
    width: 240px;
  }

  .radial {
    height: 386px;
    width: 386px;
  }

  .welcome-front {
    font-size: 32px;
  }

  #hey {
    font-size: 16px;
    top: 48px;
  }

  #logo {
    height: 32px;
    width: 32px;
    bottom: 40px;
  }

  #main {
    width: 27px;
    height: 27px;
  }

  .video {
    display: none;
  }

  .popup-content {
    height: 60vh;
    width: 70vw;
  }

  #mobile-video {
    display: block;
  }
}